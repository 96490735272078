<template>
  <div>
    <section class="section has-background-black has-text-centered">
      <br>
      <img class="image is-inline-block" :src="require('@/assets/logo.png')" alt="Logo">
      <br>
      <iframe width='100%' height='100'
              src='https://radiosaofrancisco.vipfm.net/embed#primaryColor=#0a0a0a&secondaryColor=#ff0000'
              frameborder='0' allowfullscreen="true"/>
      <br>


      <div class="columns">
        <div class="column">
          <center>
            <figure class="image is-96x96">
              <a href="https://play.google.com/store/apps/details?id=vipfm.radiosaofrancisco" target="_blank">
                <img :src="require('@/assets/android.png')" alt="Android">
              </a>
            </figure>
            <a href="https://play.google.com/store/apps/details?id=vipfm.radiosaofrancisco" target="_blank"
               class="has-text-danger">
              Baixe nosso aplicativo!
            </a>
          </center>
        </div>
      </div>

      <h1 class="has-text-white title">
        Rádio São Francisco FM
      </h1>
      <p class="has-text-white subtitle">
        A rádio da comunidade!
        <br>
        Laranjeiras do Sul - PR
      </p>
      <footer class="footer has-background-black">
        <div class="content has-text-centered has-text-white">
          <br>
          <br>
          <p>
            Website criado por Rádio São Francisco FM
          </p>
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
.is-horizontal-center {
  justify-content: center;
}
</style>
